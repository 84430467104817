import axios from '@/plugins/axios';

class UsageService {
  getUsage(data) {
    this.data = data;
    return axios().get('/usage', { params: this.data }).then((response) => response.data);
  }

  getAllUsage(data) {
    this.data = data;
    return axios().get('/usage/all', { params: this.data }).then((response) => response.data);
  }
}

export default new UsageService();
